import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';

const SignedinLinks = (props) => {
    return (
        <ul className="right">
            <li><NavLink to='/'>View All Dates</NavLink></li>
            <li><NavLink to='/create'>Create Event</NavLink></li>
            <li><a href='/' onClick={props.signOut}>Sign Out</a></li>
            <li><NavLink to='/' className="btn btn-floating teal lighten-2">RR</NavLink></li>
        </ul>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedinLinks);