import React from 'react';
import moment from 'moment';

const EventSummary = ({event}) => {
    return (
        <div className="card hoverable event-summary">
            <div className="teal lighten-3 card-action">
                <h5 className='white-text'>{event.category}</h5>
            </div>
            <div className="card-content black-text">
                <span className="card-title">{event.title}</span>
                <p><b>When:</b> {moment(event.startdate).format('MMMM Do YYYY')} at {moment(event.starttime, 'HH:mm').format('h:mm a')}</p>
                <p><b>Where:</b> {event.location}</p>
            </div>
        </div>
    )
};

export default EventSummary;