import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

const EventDetails = (props) => {
    const { event } = props;

    if (event) {
        return (
            <div className="container section event-details">
                <Link to='/'>
                    <div className="card">
                        <div className="teal lighten-3 card-action">
                            <h5 className='white-text'>{event.category}</h5>
                        </div>
                        <div className="card-content black-text">
                            <span className="card-title">{event.title}</span>
                            <p><b>When:</b> {moment(event.startdate).format('MMMM Do YYYY')} at {moment(event.starttime, 'HH:mm').format('h:mm a')}</p>
                            <p><b>Where:</b> {event.location}</p>
                            <p>&nbsp;</p>
                            <p className='flow-text'>{event.description}</p>
                        </div>
                    </div>
                </Link>
            </div>
        )
    } else {
        return (
            <div className="container centered">
                <p>Loading Event ...</p>
            </div>

        )
    }
};

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const events = state.firestore.data.events;
    const event = events ? events[id] : null;
    return {
        event: event
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'events' }
    ])
)(EventDetails);