import React from 'react';

const VersionInfo = () => {
    return (
        <div className='container'>
            <div className='section'>
                <p><b>Version: 1.2 - Dec 23 2019</b></p>
                <ul>
                    <li>fixed navbar mobile brand-logo issue</li>
                    <li>fixed event filter to include events same day</li>
                    <li>add footer</li>
                </ul>
            </div>
            <div className='section'>
                <p><b>Version: 1.1 - Dec 22 2019</b></p>
                <ul>
                    <li>changed to only pull events from today forward</li>
                    <li>added version info</li>
                    <li>adjust formatting and colors</li>
                </ul>
            </div>
            <div className="section">
                <p><b>Version: 1.0 - Dec 22 2019</b></p>
                <ul>
                    <li>finished a working version of calender list</li>
                    <li>allows singin to create new events</li>
                    <li>connect to firebase auth and firestore</li>
                </ul>
            </div>
            <div className='section'>
                <p><b>Version: 0.1 - Oct 2019</b></p>
                <ul>
                    <li>early attempt at calender connected to firestore, but manual add events in firestore</li>
                </ul>
            </div>
        </div>
    );
};

export default VersionInfo;