import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Dashboard from './components/layout/Dashboard';
import EventDetails from './components/events/EventDetails';
import Signin from './components/auth/Signin';
import CreateEvent from './components/events/CreateEvent';
import Version from './components/layout/Version';
import VersionInfo from './components/layout/VersionInfo';
import Footer from './components/layout/Footer';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Version />
        <Switch>
          <Route exact path='/' component={Dashboard} />
          <Route path='/event/:id' component={EventDetails} />
          <Route path='/signin' component={Signin} />
          <Route path='/create' component={CreateEvent} />
          <Route path='/versioninfo' component={VersionInfo} />
        </Switch>
        <Footer />
      </div></BrowserRouter>
  );
}

export default App;
