import React from 'react';
import { Link } from 'react-router-dom';

const Version = () => {
    return (
        <div className="container">
            <div className="right">
                <Link to='/versioninfo' className="grey-text darken-3">Version: 1.2</Link>
            </div>
        </div>
    );
};

export default Version;