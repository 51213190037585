import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDvF7oPPSkdoHXATuV0IMWoOIRFI-_gz94",
    authDomain: "remejy-masonic-calander.firebaseapp.com",
    databaseURL: "https://remejy-masonic-calander.firebaseio.com",
    projectId: "remejy-masonic-calander",
    storageBucket: "remejy-masonic-calander.appspot.com",
    messagingSenderId: "909227025679",
    appId: "1:909227025679:web:0f6dd1a42ac5f6c21bca7a",
    measurementId: "G-1CSCC8L2PV"
};
firebase.initializeApp(firebaseConfig);

export default firebase;