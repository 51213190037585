import React from 'react';
import SignedinLinks from './SignedinLinks';
import SignedoutLinks from './SignedoutLinks';
import { connect } from 'react-redux';

const Navbar = (props) => {
    const { auth } = props;
    const links = auth.uid ? <SignedinLinks /> : <SignedoutLinks />;

    return (
        <nav className="nav-wrapper blue">
            <div className="container">
                <a href="https://pcmason.org/" className="text-left">PCMason.org</a>
                { links }
            </div>
        </nav>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

export default connect(mapStateToProps)(Navbar);