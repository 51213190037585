import React from 'react';

const Footer = () => {
    return (
        <div className="footer teal darken-2">
            <div className="row">
                <div className="col s12 m6">
                    <p>Thanks for using this calendar feature. I would like to add as many Masonic events
                        from the various bodies as possible. If you have an event you would like to share
                        then please send an email to <br />events @ pcmason.org<br /> with the following information:
                    </p>
                    <ul className="browser-default">
                        <li><b>Category:</b> Usually Lodge Name, District, or appendant body name</li>
                        <li><b>Title:</b> Very brief title for event</li>
                        <li><b>Location:</b> Where event to be held</li>
                        <li><b>Start Date / Time:</b> in format similar to: Nov 1, 2020 at 7:30pm</li>
                        <li><b>Description:</b> Good description of what event is about. Currently only text can
                            be added at this time. Possible future may include small attachments.
                        </li>
                    </ul>
                </div>
                <div className='col s12 m6'><p className="footer-ad"></p></div>
            </div>
        </div>
    )
}

export default Footer;