import React, { Component } from 'react';
import EventList from '../events/EventList';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import moment from 'moment';

class Dashboard extends Component {
    render() {
        const { events } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div className="col s12">
                        <EventList events={events} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        events: state.firestore.ordered.events
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'events', where: [ 'startdate', '>=', moment().format('YYYY-MM-DD') ], orderBy: [ 'startdate', 'asc' ] }
    ])
)(Dashboard);