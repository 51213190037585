import React from 'react';
import { NavLink } from 'react-router-dom';

const SignedoutLinks = () => {
    return (
        <ul className="right">
            <li><NavLink to='/'>View All Dates</NavLink></li>
            <li><NavLink to='/signin'>Sign In</NavLink></li>
        </ul>
    );
};

export default SignedoutLinks;