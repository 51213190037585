import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createEvent } from '../../store/actions/eventActions';
import { Redirect } from 'react-router-dom';

class CreateEvent extends Component {
    state = {
        category: '',
        title: '',
        location: '',
        description: '',
        startdate: null,
        starttime: null
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleDateFocus = (e) => {
        e.target.type='date';
    }
    handleTimeFocus = (e) => {
        e.target.type='time';
    }
    handleDateTimeBlur = (e) => {
        e.target.type='text';
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.createEvent(this.state);
        this.props.history.push('/');
    }

    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to='/' />;

        return (
            <div className="container">
                <form onSubmit={this.handleSubmit} className="white">
                    <h5 className="grey-text text-darken-3">Create Event</h5>
                    <div className="input-field">
                        <label htmlFor="category">Category (Lodge Name, District, GL)</label>
                        <input type="text" id="category" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="title">Title (Event Title)</label>
                        <input type="text" id="title" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="location">Location (City, Room or Building)</label>
                        <input type="text" id="location" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="startdate">Start Date</label>
                        <input type="text" id="startdate" onFocus={this.handleDateFocus} onBlur={this.handleDateTimeBlur} onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="starttime">Start Time</label>
                        <input type="text" id="starttime" onFocus={this.handleTimeFocus} onBlur={this.handleDateTimeBlur} onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="description">Description</label>
                        <textarea className="materialize-textarea" id="description" onChange={this.handleChange}></textarea>
                    </div>
                    <div className="input-field">
                        <button className="btn teal darken-1">Create</button>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createEvent: (event) => dispatch(createEvent(event))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);